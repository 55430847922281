import axios from 'axios'
import { Dispatch } from 'react'
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from '../actions/types'

const API_URL = process.env.REACT_APP_USERPORTALAPIURL
const localStorageUserKey = 'user'

const login = async (
  dispatch: Dispatch<any>,
  username: string,
  password: string
): Promise<boolean> => {
  try {
    const response = await axios.post(API_URL + 'api/account/login', {
      username,
      password
    })
    if (response.data.token) {
      localStorage.setItem(localStorageUserKey, JSON.stringify(response.data))
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          userToken: response.data.token,
          userExpiration: response.data.expiration
        }
      })
      return true
    } else {
      dispatch({
        type: LOGIN_FAIL,
        payload: {}
      })
      return false
    }
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: {}
    })
    return false
  }
}

const logout = (dispatch: Dispatch<any>) => {
  localStorage.removeItem(localStorageUserKey)
  dispatch({
    type: LOGOUT,
    payload: {}
  })
}

const authService = {
  login,
  logout
}

export default authService
