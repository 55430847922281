import MasterdataGrid from './MasterdataGrid'

const SyncMasterdata = () => {
  return (
    <main className="sync-masterdata">
      <h1>Sync master data</h1>
      <div className="masterdata-grid-container">
        <MasterdataGrid />
      </div>
    </main>
  )
}

export default SyncMasterdata
