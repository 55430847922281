const ReorderTool = () => {
  return (
    <div>
      <h1>Reorder Tool</h1>
      <p>The new reorder tool will go here.</p>
      <a
        href={process.env.REACT_APP_REORDER_URL}
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Reorder tool{' '}
      </a>
    </div>
  )
}

export default ReorderTool
