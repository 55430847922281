import axios from 'axios'
import authHeader from './auth-header'
import Qs, { ParsedUrlQueryInput } from 'querystring'
import OpenOrder from '../models/openOrders/openorder'
import OpenOrdersFilter from '../models/openOrders/openorders-filter'
import PaginationResponse from '../models/pagination/paginationresponse'

const API_URL = process.env.REACT_APP_USERPORTALAPIURL + 'api'

const getOpenOrders = async (
  filters: OpenOrdersFilter
): Promise<PaginationResponse<OpenOrder>> => {
  try {
    const url = API_URL + '/openOrders'

    const response = await axios.get(url, {
      params: filters,
      paramsSerializer: (params: ParsedUrlQueryInput) => Qs.stringify(params),
      headers: authHeader()
    })

    const responseObject = {
      ...response.data,
      results: response.data.results.map(mapOpenOrder)
    }

    return responseObject
  } catch (error) {
    console.error(`Error fetching open orders: ${error}`)
    return {} as PaginationResponse<OpenOrder>
  }
}

const mapOpenOrder = (order: any): OpenOrder => {
  return {
    poNumber: order.poNumber,
    createDate: order.orderDate,
    expectedDeliveryDate: order.requestedDeliveryDate,
    customerName:
      order.customerFirstName + ' ' + order.customerLastName,
    customerEmail: order.customerEmail,
    warehouseCode: order.warehouseCode,
    status: order.orderStatus
  } as OpenOrder
}

const openOrdersService = {
  getOpenOrders
}

export default openOrdersService
