import { useEffect, useState } from 'react'
import DeliveryOption from '../../models/deliveryOptions/deliveryOption'
import Switch from 'react-switch'

interface CarrierSelectionTableRowProps {
  deliveryOption: DeliveryOption
  setDeliveryOptionsUpdated: React.Dispatch<
    React.SetStateAction<DeliveryOption[]>
  >
}

const CarrierSelectionTableRow = ({
  deliveryOption,
  setDeliveryOptionsUpdated
}: CarrierSelectionTableRowProps) => {
  const [activated, setActivated] = useState(deliveryOption.activated)
  const [priority, setPriority] = useState(deliveryOption.priority)
  const [deliveryMethodName, setDeliveryMethodName] = useState(
    deliveryOption.deliveryMethodName
  )

  useEffect(() => {
    if (
      deliveryOption.activated !== activated ||
      deliveryOption.priority !== priority ||
      deliveryOption.deliveryMethodName !== deliveryMethodName
    ) {
      // One or more property changed for Delivery Option. Add changes to state
      setDeliveryOptionsUpdated((prevState) => {
        return prevState.map((item) => {
          return item.deliveryMethodId === deliveryOption.deliveryMethodId
            ? {
                ...item,
                activated: activated,
                deliveryMethodName: deliveryMethodName,
                priority: priority,
                updated: true
              }
            : item
        })
      })
    } else {
      // No value change for Delivery Option. Remove changes to initial state
      setDeliveryOptionsUpdated((prevState) => {
        return prevState.map((item) => {
          return item.deliveryMethodId === deliveryOption.deliveryMethodId
            ? {
                ...item,
                activated: deliveryOption.activated,
                deliveryMethodName: deliveryOption.deliveryMethodName,
                priority: deliveryOption.priority,
                updated: false
              }
            : item
        })
      })
    }
  }, [
    deliveryOption,
    activated,
    priority,
    deliveryMethodName,
    setDeliveryOptionsUpdated
  ])

  return (
    <tr>
      <td>
        <label htmlFor="carrier-activated">{deliveryOption.carrierName}</label>
      </td>
      <td>
        <Switch
          checked={activated}
          onChange={(checked) => setActivated(checked)}
        />
      </td>
      <td>
        <input
          type="text"
          maxLength={50}
          value={deliveryMethodName}
          onChange={(e) => setDeliveryMethodName(e.target.value)}
        />
      </td>
      <td>
        <input
          type="number"
          min={1}
          value={priority}
          onChange={(e) => setPriority(Number(e.target.value))}
        />
      </td>
    </tr>
  )
}

export default CarrierSelectionTableRow
