import axios, { AxiosResponse } from 'axios'
import authHeader from './auth-header'
import MasterdataFilter from '../models/masterdata/masterdata-filters'
import MasterdataSyncronizationFilter from '../models/masterdata/masterdata-filters-syncronization'
import PaginationResponse from '../models/pagination/paginationresponse'
import Qs from 'querystring'
import MasterdataDTO from '../models/masterdata/masterdata-dto'

const API_URL = process.env.REACT_APP_USERPORTALAPIURL + 'api'

const getFilterParams = (filterParams: MasterdataFilter) => {
  return filterParams.syncStatuses?.length
    ? filterParams
    : {
        pageIndex: filterParams.pageIndex,
        pageSize: filterParams.pageSize
      }
}

const getMasterdata = async (
  filters: MasterdataFilter
): Promise<PaginationResponse<MasterdataDTO>> => {
  try {
    const url = API_URL + '/masterdata'
    const filterParams = getFilterParams(filters)

    const response = await axios.get(url, {
      params: filterParams,
      paramsSerializer: (params) => Qs.stringify(params),
      headers: authHeader()
    })

    return response.data
  } catch (error) {
    console.error(`Error fetching Masterdata: ${error}`)
    return {} as PaginationResponse<MasterdataDTO>
  }
}

const getMasterdataItem = async (
  articleNumber: string
): Promise<MasterdataDTO> => {
  const url = API_URL + `/masterdata/${articleNumber}`
  return axios
    .get(url, {
      headers: authHeader()
    })
    .then((result) => result.data)
}

const syncMasterdata = async (
  filters: MasterdataSyncronizationFilter
): Promise<AxiosResponse> => {
  const url = API_URL + '/masterdata/syncMasterData'
  return axios.post(url, filters, {
    headers: authHeader()
  })
}

const masterdataService = {
  getMasterdata,
  getMasterdataItem,
  syncMasterdata
}

export default masterdataService
