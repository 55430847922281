import OrderRetryGrid from './OrderRetryGrid'

const OrderRetryOverview = () => {
  return (
    <main className="order-retry-overview">
      <h1>Failed warehouse orders</h1>
      <div className="order-retry-grid-container">
        <OrderRetryGrid />
      </div>
    </main>
  )
}

export default OrderRetryOverview
