import { AxiosRequestHeaders } from 'axios'
import localStorageService from './localStorage-service'

export default function authHeader(): AxiosRequestHeaders {
  const user = localStorageService.getLocalUser()

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token }
  } else {
    return {}
  }
}
