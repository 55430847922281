import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from '../actions/types'
import { LoginState } from '../services/localStorage-service'
import localStorageService from '../services/localStorage-service'
import { Action } from '../actions/templates/actionTemplate'

const user = localStorageService.getLocalUser()
const initialState: LoginState = user
  ? {
      isLoggedIn: true,
      userToken: user.token,
      userExpiration: user.expiration
    }
  : {
      isLoggedIn: false,
      userToken: '',
      userExpiration: ''
    }

const authReducer = (state = initialState, action: Action<LoginState>) => {
  const { type, payload } = action

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userToken: payload.userToken,
        userExpiration: payload.userExpiration
      }
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        userToken: '',
        userExpiration: ''
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        userToken: '',
        userExpiration: ''
      }
    default:
      return state
  }
}

export default authReducer
