import { useCallback } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import DataGridOptions from '../../models/dataGrid/datagrid-options'
import openOrdersService from '../../services/openOrders-service'
import { OrderStatus } from '../../models/enums/order-status'
import { WarehouseCodes } from '../../models/enums/warehouse-codes'
import gridColumns from '../../models/dataGrid/openorders-gridcolumns'

const defaultWarehouseCodes = [
  WarehouseCodes.EuropaUk,
  WarehouseCodes.KnItaly,
  WarehouseCodes.KnUtrecht
]
const defaultOrderStatuses = [
  OrderStatus.Enqueued,
  OrderStatus.FailedToQueue,
  OrderStatus.SentToWarehouse,
  OrderStatus.FailedToSend,
  OrderStatus.InProgress,
  OrderStatus.AwaitingExchange,
  OrderStatus.PartiallyAcknowledged,
  OrderStatus.FullyAcknowledged
]

const defaultFilterValue = [
  { name: 'poNumber', type: 'string', operator: 'contains', value: '' },
  { name: 'warehouseCode', type: 'select', operator: 'inlist', value: [] },
  {
    name: 'status',
    type: 'select',
    operator: 'inlist',
    value: [] as string[]
  }
]

const fetchOpenOrders = async ({
  skip,
  limit,
  filterValue
}: DataGridOptions) => {
  const page = Math.floor(skip / limit)

  const filteredWarehouseCodes = filterValue?.find(
    (c) => c.name === 'warehouseCode'
  )?.value
  const filteredOrderStatuses = filterValue?.find(
    (c) => c.name === 'status'
  )?.value
  const filteredPoNumber = filterValue?.find(
    (c) => c.name === 'poNumber'
  )?.value

  const warehouseCodes =
    filteredWarehouseCodes && filteredWarehouseCodes.length
      ? filteredWarehouseCodes
      : defaultWarehouseCodes
  const orderStatuses =
    filteredOrderStatuses && filteredOrderStatuses.length
      ? filteredOrderStatuses
      : defaultOrderStatuses

  const sevenDaysAgo = new Date()
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)
  const currentDate = new Date()

  return openOrdersService
    .getOpenOrders({
      pageIndex: page,
      pageSize: limit,
      startDate: sevenDaysAgo.toISOString(),
      endDate: currentDate.toISOString(),
      warehouseCodes: warehouseCodes,
      statuses: orderStatuses,
      trackingNumber: filteredPoNumber
    })
    .then((response) => {
      return {
        data: response.results ?? [],
        count: response.total ?? 0
      }
    })
}

const OpenOrdersGrid = () => {
  const dataSource = useCallback(fetchOpenOrders, [])
  return (
    <section className="data-grid-container">
      <ReactDataGrid
        idProperty="poNumber"
        className="data-grid"
        columns={gridColumns}
        dataSource={dataSource}
        pagination={true}
        showEmptyRows={true}
        defaultFilterValue={defaultFilterValue}
        defaultSortInfo={{ name: 'expectedDeliveryDate', dir: 1 }}
        checkboxColumn={true}
      />
    </section>
  )
}

export default OpenOrdersGrid
