import { useAppSelector } from '../../app/hooks'
import { Navigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

const Logout = () => {
  
  const isLoggedIn = useAppSelector((state) => state.authReducer.isLoggedIn)

  return (    
    <div>
      {isLoggedIn && (
      <Navigate to="/" replace={true} />
    )}
      <h1>See you soon!</h1>      
      <p>You have been successfully logged out of your account.</p>
	    <p>
		    <Link className='logout-button' to="/login">Login again</Link>
	    </p>
    </div>
  )
}

export default Logout
