import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import DataGridOptions from '../../models/dataGrid/datagrid-options'
import masterdataService from '../../services/masterdata-service'
import { useCallback, useEffect, useState } from 'react'
import { MasterdataSyncStatus } from '../../models/enums/masterdata-enums'
import { enumToArray } from '../../helpers/helpers'
import { FaSyncAlt } from 'react-icons/fa'
import MasterdataSyncronizationFilter from '../../models/masterdata/masterdata-filters-syncronization'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import gridColumns from '../../models/dataGrid/masterdata-gridcolumns'

const defaultFilterValue = [
  { name: 'syncStatus', type: 'select', operator: 'inlist', value: [] }
]

const SyncButtonText = {
  syncSelected: 'Sync Selected Masterdata',
  syncPending: 'Sync Pending Masterdata'
}

const fetchMasterdata = async ({
  skip,
  limit,
  filterValue
}: DataGridOptions) => {
  const page = Math.floor(skip / limit)
  const response = await masterdataService.getMasterdata({
    pageIndex: page,
    pageSize: limit,
    syncStatuses: filterValue[0].value
  })
  return {
    data: response.results ?? [],
    count: response.total ?? 0
  }
}

const MasterdataGrid = () => {
  const [selectedArticleNumbers, setSelectedArticleNumbers] = useState<
    string[]
  >([])
  const [allSelected, setAllSelected] = useState(false)
  const [syncButtonText, setSyncButtonText] = useState(
    SyncButtonText.syncPending
  )
  const navigate = useNavigate()
  const dataSource = useCallback(fetchMasterdata, [])

  const onSelectionChange = useCallback(({ selected }) => {
    /* If the user selects all, selected will be true.
         Otherwise, if the user has selected one or more individual
         items, selected will be an object keyed by the selected values. */
    if (selected === true) {
      setAllSelected(true)
    } else {
      const selectedItems = Object.keys(selected)
      setAllSelected(false)
      setSelectedArticleNumbers(selectedItems)
    }
  }, [])

  const onRowClick = useCallback(
    (rowProps) => {
      navigate(rowProps.data.articleNumber)
    },
    [navigate]
  )

  useEffect(() => {
    if (allSelected || selectedArticleNumbers.length) {
      setSyncButtonText(SyncButtonText.syncSelected)
    } else {
      setSyncButtonText(SyncButtonText.syncPending)
    }
  }, [allSelected, selectedArticleNumbers])

  const syncMasterdata = async () => {
    const filter = {} as MasterdataSyncronizationFilter

    if (allSelected) {
      // scenario 1: all selected
      filter.syncStatuses = enumToArray(MasterdataSyncStatus)
    } else if (selectedArticleNumbers.length) {
      // scenario 2: some selected, not all
      filter.articleNumbers = selectedArticleNumbers
    } else {
      // scenario 3: only pending
      filter.syncStatuses = [
        MasterdataSyncStatus.PendingSync.toString(),
        MasterdataSyncStatus.PartiallySynced.toString()
      ]
    }

    await toast.promise(masterdataService.syncMasterdata(filter), {
      pending:
        "🔄 Syncing Masterdata. You'll be notified when the sync completes.",
      success: '✅ Masterdata sync was successful!',
      error: '😞 The sync failed.'
    })
  }

  return (
    <>
      <section className="data-grid-container">
        <ToastContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="button-row">
          <button
            className="brwlfBtn"
            onClick={async () => await syncMasterdata()}
          >
            <FaSyncAlt className="sync-btn-deco" />
            &nbsp;
            {syncButtonText}
          </button>
        </div>
        <ReactDataGrid
          idProperty="articleNumber"
          className="data-grid"
          columns={gridColumns}
          dataSource={dataSource}
          pagination={true}
          showEmptyRows={true}
          defaultFilterValue={defaultFilterValue}
          checkboxColumn={true}
          onSelectionChange={onSelectionChange}
          onRowClick={onRowClick}
        />
      </section>
      <Outlet />
    </>
  )
}

export default MasterdataGrid
