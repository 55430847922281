const Dashboard = () => {
  return (
    <div>
      <h1>This is the dashboard component.</h1>
      <p>Here will be the dashboard.</p>
    </div>
  )
}

export default Dashboard
