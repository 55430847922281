import axios from 'axios'
import DeliveryOption from '../models/deliveryOptions/deliveryOption'
import DeliveryOptionsFilter from '../models/deliveryOptions/deliveryOptionsFilter'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_USERPORTALAPIURL + 'api/DeliveryOptions'

const getDeliveryOptions = async (
  filter?: DeliveryOptionsFilter
): Promise<DeliveryOption[]> => {
  return axios
    .get(API_URL, {
      headers: authHeader(),
      params: {
        DeliveryMethodId: filter?.DeliveryMethodId,
        DeliveryMethodName: filter?.DeliveryMethodName,
        MarketId: filter?.MarketId
      }
    })
    .then((result) => result.data)
}

const saveDeliveryOption = async (
  deliveryOption: DeliveryOption
): Promise<DeliveryOption> => {
  return axios
    .post(
      API_URL,
      { deliveryOption: deliveryOption },
      { headers: authHeader() }
    )
    .then((result) => result.data)
}

const updateDeliveryOptions = async (
  deliveryOptions: DeliveryOption[]
): Promise<DeliveryOption[]> => {
  return axios
    .put(
      API_URL,
      { deliveryOptions: deliveryOptions },
      { headers: authHeader() }
    )
    .then((result) => result.data)
}

const deliveryOptionsService = {
  getDeliveryOptions,
  saveDeliveryOption,
  updateDeliveryOptions
}

export default deliveryOptionsService
