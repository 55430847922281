import Sidebar from './Sidebar'
import App from '../core/App'
import { useState } from 'react'

const Layout = () => {
  const [collapsed, setCollapsed] = useState(false)

  const handleCollapse = (collapse: boolean) => {
    setCollapsed(collapse)
  }

  return (
    <main className="home">
      <div className="container">
        <aside className="sidebar">
          <Sidebar collapsed={collapsed} handleCollapse={handleCollapse} />
        </aside>
        <main className="content">
          <App />
        </main>
      </div>
    </main>
  )
}

export default Layout
