import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import { enumToArray } from '../../helpers/helpers'
import { OrderStatus } from '../enums/order-status'
import { WarehouseCodes } from '../enums/warehouse-codes'

const gridColumns = [
  {
    name: 'poNumber',
    header: 'PO Number',
    defaultFlex: 1,
    type: 'string'
  },
  {
    name: 'createDate',
    header: 'Order Date',
    defaultFlex: 1,
    type: 'string',
    render: ({ data }: any) => {
      return new Date(data.createDate).toLocaleString()
    },
    sort: (date1: string, date2: string) => {
      return new Date(date1).getTime() - new Date(date2).getTime()
    }
  },
  {
    name: 'expectedDeliveryDate',
    header: 'Expected Delivery',
    defaultFlex: 1,
    type: 'string',
    render: ({ data }: any) => {
      return new Date(data.expectedDeliveryDate).toLocaleString()
    },
    sort: (date1: string, date2: string) => {
      return new Date(date1).getTime() - new Date(date2).getTime()
    }
  },
  {
    name: 'customerName',
    header: 'Name',
    defaultFlex: 1,
    type: 'string'
  },
  {
    name: 'customerEmail',
    header: 'Email',
    defaultFlex: 1,
    type: 'string'
  },
  {
    name: 'warehouseCode',
    header: 'Warehouse Code',
    defaultFlex: 1,
    type: 'string',
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: Object.values(WarehouseCodes).map((val) => {
        return { id: val, label: val }
      })
    }
  },
  {
    name: 'status',
    header: 'Status',
    defaultFlex: 1,
    type: 'string',
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: enumToArray(OrderStatus).map((val) => {
        return { id: val, label: val }
      })
    }
  }
]

export default gridColumns
