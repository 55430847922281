import { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DeliveryOption from '../../models/deliveryOptions/deliveryOption'
import DeliveryOptionsMarket from '../../models/deliveryOptions/deliveryOptionsMarket'
import deliveryOptionsService from '../../services/deliveryOptions-service'
import CarrierSelectionPopup from './CarrierSelectionPopup'
import CarrierSelectionTable from './CarrierSelectionTable'

const defaultMarket: DeliveryOptionsMarket = { name: 'UK', id: 'bwukb2c' }

const CarrierSelection = () => {
  const [disableButton, setDisableButton] = useState(true)
  const [showPopup, setShowPopup] = useState(false)

  const [deliveryOptionsInitial, setDeliveryOptionsInitial] = useState<
    DeliveryOption[]
  >([])
  const [deliveryOptionsUpdated, setDeliveryOptionsUpdated] = useState<
    DeliveryOption[]
  >([])
  const [deliveryOptionsToSave, setDeliveryOptionsToSave] = useState<
    DeliveryOption[]
  >([])

  useEffect(() => {
    deliveryOptionsService.getDeliveryOptions().then((result) => {
      const sortedDeliveryOptions = result.sort((a, b) => {
        return a.priority - b.priority
      })
      setDeliveryOptionsInitial([...sortedDeliveryOptions])
      setDeliveryOptionsUpdated([...sortedDeliveryOptions])
    })
  }, [])

  useEffect(() => {
    const uniqueSet = new Set(deliveryOptionsUpdated.map((v) => v.priority))
    const samePriorityExists = uniqueSet.size < deliveryOptionsUpdated.length
    const toSave = deliveryOptionsUpdated
      .filter((x) => x.updated === true)
      .map((deliveryOption) => {
        return { ...deliveryOption, updated: undefined }
      })

    setDeliveryOptionsToSave([...toSave])

    if (toSave.length === 0) {
      setDisableButton(true)
      setShowPopup(false)
    } else {
      setDisableButton(false)
      setShowPopup(samePriorityExists)
    }
  }, [deliveryOptionsUpdated])

  const handleUpdateDeliveryOptions = async () => {
    const updateDeliveryOptionsPromise = deliveryOptionsService
      .updateDeliveryOptions(deliveryOptionsToSave)
      .then(() => {
        setDeliveryOptionsInitial([...deliveryOptionsUpdated])
        setDeliveryOptionsUpdated([...deliveryOptionsUpdated])
      })

    await toast.promise(updateDeliveryOptionsPromise, {
      pending: 'Saving carrier selection.',
      success: 'Save was successful!',
      error: 'Save failed. Please try again.'
    })
  }

  const buttonElement = showPopup ? (
    <CarrierSelectionPopup
      header="There is another delivery option with the same position. Do you want to save?"
      buttonText="Yes"
      buttonClick={handleUpdateDeliveryOptions}
      triggerElement={
        <button className="brwlfBtn" disabled={disableButton}>
          Save
        </button>
      }
    />
  ) : (
    <button
      onClick={handleUpdateDeliveryOptions}
      className="brwlfBtn"
      disabled={disableButton}
    >
      Save
    </button>
  )

  return (
    <main className="carrier-selection">
      <h1>Carrier selection</h1>
      <div className="button-row">{buttonElement}</div>
      <CarrierSelectionTable
        key={defaultMarket.id}
        marketName={defaultMarket.name}
        deliveryOptions={deliveryOptionsInitial.filter(
          (x) => x.marketId === defaultMarket.id
        )}
        setDeliveryOptionsUpdated={setDeliveryOptionsUpdated}
      />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </main>
  )
}

export default CarrierSelection
