import 'react-toastify/dist/ReactToastify.css'
import Popup from 'reactjs-popup'

interface CarrierSelectionPopupProps {
  header: string
  buttonText: string
  buttonClick: Function
  triggerElement?: JSX.Element
}

const CarrierSelectionPopup = ({
  header,
  buttonText,
  buttonClick,
  triggerElement
}: CarrierSelectionPopupProps) => {
  return (
    <Popup trigger={triggerElement} position="right center" modal>
      {(close: any) => (
        <div className="carrierselection-modal">
          <div className="modal-header">{header}</div>
          <div className="modal-actions">
            <button
              className="brwlfBtn"
              onClick={() => {
                buttonClick()
                close()
              }}
            >
              {buttonText}
            </button>
            <button className="brwlfBtn brwlfBtn-gray" onClick={close}>
              No
            </button>
          </div>
        </div>
      )}
    </Popup>
  )
}

export default CarrierSelectionPopup
