import axios from 'axios'
import authHeader from './auth-header'
import OpenOrdersFilter from '../models/openOrders/openorders-filter'
import { OrderStatus } from '../models/enums/order-status'
import { WarehouseCodes } from '../models/enums/warehouse-codes'

const API_URL = process.env.REACT_APP_USERPORTALAPIURL + 'api'

const retryFailedOrders = async (): Promise<boolean> => {
  const url = API_URL + '/orderRetry'
  const orderStatuses = [OrderStatus.FailedToQueue, OrderStatus.FailedToSend]
  const warehouseCodes = [
    WarehouseCodes.KnItaly,
    WarehouseCodes.KnUtrecht,
    WarehouseCodes.EuropaUk
  ]
  const threeMonthsAgo = new Date()
  threeMonthsAgo.setDate(threeMonthsAgo.getDate() - 90)

  const requestFilter = {
    statuses: orderStatuses,
    pageIndex: 0,
    pageSize: 0,
    startDate: threeMonthsAgo.toISOString(),
    endDate: new Date().toISOString(),
    warehouseCodes: warehouseCodes,
    trackingNumber: ''
  } as OpenOrdersFilter

  try {
    await axios.post(url, requestFilter, {
      headers: authHeader()
    })

    return true
  } catch (error) {
    console.error(`Error retrying failed orders: ${error}`)
    return false
  }
}

const orderRetryService = {
  retryFailedOrders
}

export default orderRetryService
