import DeliveryOption from '../../models/deliveryOptions/deliveryOption'
import CarrierSelectionTableRow from './CarrierSelectionTableRow'

interface CarrierSelectionTableProps {
  marketName: string
  deliveryOptions: DeliveryOption[]
  setDeliveryOptionsUpdated: React.Dispatch<
    React.SetStateAction<DeliveryOption[]>
  >
}

const CarrierSelectionTable = ({
  marketName,
  deliveryOptions,
  setDeliveryOptionsUpdated
}: CarrierSelectionTableProps) => {
  return (
    <div className="carrierselection-table-container">
      <h3>{marketName}</h3>
      <table>
        <thead>
          <tr>
            <th>Carrier</th>
            <th>Active</th>
            <th className="carrierselection-display-name">Display name</th>
            <th className="carrierselection-position">Position</th>
          </tr>
        </thead>
        <tbody>
          {deliveryOptions.map((deliveryOption) => {
            return (
              <CarrierSelectionTableRow
                key={deliveryOption.deliveryMethodId.toString()}
                deliveryOption={deliveryOption}
                setDeliveryOptionsUpdated={setDeliveryOptionsUpdated}
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default CarrierSelectionTable
