import { Routes, Route } from 'react-router-dom'
import Dashboard from '../Dashboard'
import OpenOrdersOverview from '../openorders/OpenOrdersOverview'
import PreordersOverview from '../openorders/PreordersOverview'
import OrderRetryOverview from '../openorders/OrderRetryOverview'
import Login from './Login'
import RequireAuth from './RequireAuth'
import SyncMasterdata from '../masterdata/SyncMasterdata'
import MasterdataDetail from '../masterdata/MasterdataDetail'
import ReorderTool from '../ReorderTool'
import BulkOrderTool from '../BulkOrderTool'
import Logout from './Logout'
import CarrierSelection from '../carrierselection/CarrierSelection'

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/syncmasterdata"
        element={
          <RequireAuth>
            <SyncMasterdata />
          </RequireAuth>
        }
      >
        <Route
          path="/syncmasterdata/:articleNumber"
          element={
            <RequireAuth>
              <MasterdataDetail />
            </RequireAuth>
          }
        />
      </Route>
      <Route
        path="/openordersoverview"
        element={
          <RequireAuth>
            <OpenOrdersOverview />
          </RequireAuth>
        }
      />
      <Route
        path="/orderretry"
        element={
          <RequireAuth>
            <OrderRetryOverview />
          </RequireAuth>
        }
      />
      <Route
        path="/preordersoverview"
        element={
          <RequireAuth>
            <PreordersOverview />
          </RequireAuth>
        }
      />
      <Route
        path="/reordertool"
        element={
          <RequireAuth>
            <ReorderTool />
          </RequireAuth>
        }
      />
      <Route
        path="/bulkordertool"
        element={
          <RequireAuth>
            <BulkOrderTool />
          </RequireAuth>
        }
      />
      <Route
        path="/carrierselection"
        element={
          <RequireAuth>
            <CarrierSelection />
          </RequireAuth>
        }
      />
    </Routes>
  )
}

export default App
