import PreordersGrid from './PreordersGrid'

const PreordersOverview = () => {
  return (
    <main className="preorders-overview">
      <h1>Preorders Overview</h1>
      <div className="preorders-grid-container">
        <PreordersGrid />
      </div>
    </main>
  )
}

export default PreordersOverview
