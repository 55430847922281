export interface LoginState {
  isLoggedIn: boolean
  userToken: string
  userExpiration: string
}

export interface LoginResult {
  token: string
  expiration: string
}

const getLocalUser = (): LoginResult | null => {
  const localStorageUser: string = localStorage.getItem('user') ?? ''
  let user: LoginResult | null = null
  if (localStorageUser) {
    user = JSON.parse(localStorageUser)
  }

  return user
}

const localStorageService = { getLocalUser }

export default localStorageService
