const isNumber = (value: string) => !isNaN(Number(value))

export const enumToArray = (enumeration: any): string[] => {
  return Object.keys(enumeration)
    .filter(isNumber)
    .map((key) => enumeration[key])
}

/* adapted from https://codepen.io/decodigo/pen/JjzWwr */
export const jsonPrettyPrint = (json: any) => {
  var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm
  var replacer = function (
    match: string,
    pIndent: string,
    pKey: string,
    pVal: string,
    pEnd: number
  ) {
    var key = '<span class="json-key" style="color: brown">',
      val = '<span class="json-value" style="color: navy">',
      str = '<span class="json-string" style="color: olive">',
      r = pIndent || ''
    if (pKey) r = r + key + pKey.replace(/[": ]/g, '') + '</span>: '
    if (pVal) r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>'
    return r + (pEnd || '')
  }

  return JSON.stringify(json, null, 3).replace(jsonLine, replacer)
}
