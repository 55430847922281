import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser'
import { jsonPrettyPrint } from '../../helpers/helpers'
import MasterdataDTO from '../../models/masterdata/masterdata-dto'
import masterdataService from '../../services/masterdata-service'
import { useNavigate } from 'react-router-dom'

const MasterdataDetail = () => {
  const { articleNumber } = useParams()
  const [masterdataItem, setMasterdataItem] = useState<MasterdataDTO>(
    {} as MasterdataDTO
  )
  const navigate = useNavigate()

  useEffect(() => {
    masterdataService
      .getMasterdataItem(articleNumber ?? '')
      .then((result: MasterdataDTO) => {
        setMasterdataItem(result)
      })
  }, [articleNumber])

  const onClose = () => {
    navigate('/syncmasterdata')
  }

  return (
    <section className="masterdata-detail">
      <div className="close" onClick={onClose}>
        X
      </div>
      <pre>
        <code>
          {masterdataItem
            ? parse(jsonPrettyPrint(masterdataItem))
            : 'Click on an item to view its details.'}
        </code>
      </pre>
    </section>
  )
}

export default MasterdataDetail
