export enum MasterdataSyncStatus {
  None,
  PendingSync,
  PartiallySynced,
  CompletelySynced
}

export enum MasterdataContainerType {
  Bottle = 0,
  Can = 1,
  Torp = 2,
  DraughtKeg = 3,
  BladeKeg = 4
}

export enum MasterdataPaletteType {
  Case = 0,
  Crate = 1,
  Single = 2
}
