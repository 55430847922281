import React, { useState } from 'react'
import { WarehouseCodes } from '../../models/enums/warehouse-codes'
import preOrderService from '../../services/preorder-service'
import { toast } from 'react-toastify'

const PreOrderTool = () => {
  const [warehouse, setWarehouse] = useState('')
  const [date, setDate] = useState(new Date())

  const handleSubmit = async () => {
    console.log(warehouse + '-' + date)
    await toast.promise(preOrderService.sendPreorders(warehouse, date), {
      pending: '🔄 Sending PreOrders.',
      success: '✅ PreOrders were sent successfully!',
      error: '😞 Sending PreOrders failed.'
    })
  }

  const handleMarketChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    e.preventDefault()
    setWarehouse(e.target.value)
  }

  const handleDateChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()
    setDate(new Date(e.target.value))
  }

  const defaultWarehouseOptions = [
    <option key={WarehouseCodes.EuropaUk}> {WarehouseCodes.EuropaUk} </option>,
    <option key={WarehouseCodes.KnItaly}> {WarehouseCodes.KnItaly} </option>,
    <option key={WarehouseCodes.KnUtrecht}> {WarehouseCodes.KnUtrecht} </option>
  ]

  var dateNow = new Date(new Date()).toISOString().split('T')[0]

  return (
    <div className="preorder-tool">
      <p>
        This action will send <b>ALL</b> preorders!!!
      </p>
      <div className="preorder-form">
        <select defaultValue={'default'} onChange={handleMarketChange}>
          <option value="default" disabled hidden>
            Warehouse
          </option>
          {defaultWarehouseOptions}
        </select>
        <input
          type="date"
          placeholder="delivery date"
          defaultValue={dateNow}
          onChange={handleDateChange}
        />
        <button className="submit" onClick={handleSubmit}>
          Send
        </button>
      </div>
    </div>
  )
}

export default PreOrderTool
