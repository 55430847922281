import { useCallback} from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import DataGridOptions from '../../models/dataGrid/datagrid-options'
import openOrdersService from '../../services/openOrders-service'
import { OrderStatus } from '../../models/enums/order-status'
import { WarehouseCodes } from '../../models/enums/warehouse-codes'
import gridColumns from '../../models/dataGrid/openorders-gridcolumns'
import { FaPaperPlane } from 'react-icons/fa'
import PreorderTool from './PreOrderTool'
import Popup from 'reactjs-popup';
import { ToastContainer } from 'react-toastify'


const defaultFilterValue = [
  { name: 'warehouseCode', type: 'select', operator: 'inlist', value: [] }
]

const defaultWarehouseCodes = [
  WarehouseCodes.EuropaUk,
  WarehouseCodes.KnItaly,
  WarehouseCodes.KnUtrecht
]

const defaultOrderStatuses = [OrderStatus.PreOrder]

const fetchPreorders = async ({
  skip,
  limit,
  filterValue
}: DataGridOptions) => {
  const page = Math.floor(skip / limit)

  const filteredWarehouseCodes = filterValue?.find(
    (c) => c.name === 'warehouseCode'
  )?.value

  const warehouseCodes =
    filteredWarehouseCodes && filteredWarehouseCodes.length
      ? filteredWarehouseCodes
      : defaultWarehouseCodes

  const oneYearAgo = new Date()
  oneYearAgo.setDate(oneYearAgo.getDate() - 365)
  const oneYearAhead = new Date()
  oneYearAhead.setDate(oneYearAhead.getDate() + 365)

  return openOrdersService
    .getOpenOrders({
      pageIndex: page,
      pageSize: limit,
      startDate: oneYearAgo.toISOString(),
      endDate: oneYearAhead.toISOString(),
      warehouseCodes: warehouseCodes,
      statuses: defaultOrderStatuses,
      trackingNumber: ''
    })
    .then((response) => {
      return {
        data: response.results ?? [],
        count: response.total ?? 0
      }
    })
}

const PreordersGrid = () => {
  const dataSource = useCallback(fetchPreorders, [])

  return (
    <section className="data-grid-container">
      <ToastContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      <div className="button-row">
        <Popup trigger={<button className="brwlfBtn">
            <FaPaperPlane className="sync-btn-deco" />
            &nbsp;
            Send PreOrders
            </button>} modal>
              <PreorderTool/>
        </Popup>
      </div>
      
      <ReactDataGrid
        idProperty="poNumber"
        className="data-grid"
        columns={gridColumns}
        dataSource={dataSource}
        pagination={true}
        showEmptyRows={true}
        defaultFilterValue={defaultFilterValue}
        checkboxColumn={true}
      />
    </section>
  )
}

export default PreordersGrid