import { useCallback } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import DataGridOptions from '../../models/dataGrid/datagrid-options'
import openOrdersService from '../../services/openOrders-service'
import { OrderStatus } from '../../models/enums/order-status'
import { WarehouseCodes } from '../../models/enums/warehouse-codes'
import gridColumns from '../../models/dataGrid/openorders-gridcolumns'
import orderRetryService from '../../services/orderRetry-service'
import { ToastContainer, toast } from 'react-toastify'
import { FaSyncAlt } from 'react-icons/fa'

const defaultWarehouseCodes = [
  WarehouseCodes.KnItaly,
  WarehouseCodes.KnUtrecht,
  WarehouseCodes.EuropaUk
]
const defaultOrderStatuses = [
  OrderStatus.FailedToQueue,
  OrderStatus.FailedToSend
]

const defaultFilterValue = [
  { name: 'poNumber', type: 'string', operator: 'contains', value: '' },
  {
    name: 'warehouseCode',
    type: 'select',
    operator: 'inlist',
    value: [
      WarehouseCodes.KnItaly,
      WarehouseCodes.KnUtrecht,
      WarehouseCodes.EuropaUk
    ]
  },
  {
    name: 'status',
    type: 'select',
    operator: 'inlist',
    value: ['FailedToQueue', 'FailedToSend']
  }
]

const fetchOpenOrders = async ({
  skip,
  limit,
  filterValue
}: DataGridOptions) => {
  const page = Math.floor(skip / limit)

  const filteredWarehouseCodes = filterValue?.find(
    (c) => c.name === 'warehouseCode'
  )?.value
  const filteredOrderStatuses = filterValue?.find(
    (c) => c.name === 'status'
  )?.value
  const filteredPoNumber = filterValue?.find(
    (c) => c.name === 'poNumber'
  )?.value

  const warehouseCodes =
    filteredWarehouseCodes && filteredWarehouseCodes.length
      ? filteredWarehouseCodes
      : defaultWarehouseCodes
  const orderStatuses =
    filteredOrderStatuses && filteredOrderStatuses.length
      ? filteredOrderStatuses
      : defaultOrderStatuses

  const threeMonthsAgo = new Date()
  threeMonthsAgo.setDate(threeMonthsAgo.getDate() - 90)
  const currentDate = new Date()

  return openOrdersService
    .getOpenOrders({
      pageIndex: page,
      pageSize: limit,
      startDate: threeMonthsAgo.toISOString(),
      endDate: currentDate.toISOString(),
      warehouseCodes: warehouseCodes,
      statuses: orderStatuses,
      trackingNumber: filteredPoNumber
    })
    .then((response) => {
      return {
        data: response.results ?? [],
        count: response.total ?? 0
      }
    })
}

const OrderRetryGrid = () => {
  const dataSource = useCallback(fetchOpenOrders, [])

  const handleRetryClick = async () => {
    await toast.promise(orderRetryService.retryFailedOrders(), {
      pending: '🔄 Retrying failed orders.',
      success: '✅ The failed orders have been resent.',
      error: '😞 Something went wrong.'
    })
  }

  return (
    <section className="retry-button-container">
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="button-row">
        <button
          className="brwlfBtn"
          onClick={async () => await handleRetryClick()}
        >
          <FaSyncAlt className="sync-btn-deco" />
          &nbsp;Retry orders
        </button>
      </div>
      <section className="data-grid-container">
        <ReactDataGrid
          idProperty="poNumber"
          className="data-grid"
          columns={gridColumns}
          dataSource={dataSource}
          pagination={true}
          showEmptyRows={true}
          defaultFilterValue={defaultFilterValue}
          defaultSortInfo={{ name: 'expectedDeliveryDate', dir: 1 }}
          enableFiltering={false}
        />
      </section>
    </section>
  )
}

export default OrderRetryGrid
