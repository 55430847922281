export enum OrderStatus {
  OnHold = 1,
  PartiallyShipped = 2,
  InProgress = 4,
  Completed = 8,
  Cancelled = 16,
  AwaitingExchange = 32,
  Enqueued = 64,
  FailedToQueue = 128,
  SentToWarehouse = 256,
  FailedToSend = 512,
  PartiallyAcknowledged = 1024,
  FullyAcknowledged = 2048,
  FullyShipped = 4096,
  PreOrder = 8192
}
