import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import { enumToArray } from '../../helpers/helpers'
import { MasterdataSyncStatus } from '../enums/masterdata-enums'

const gridColumns = [
  {
    name: 'articleNumber',
    header: 'EAN Code',
    defaultFlex: 1,
    type: 'string'
  },
  { name: 'description', header: 'Name', defaultFlex: 1, type: 'string' },
  {
    name: 'syncStatus',
    header: 'Sync Status',
    defaultFlex: 0.5,
    type: 'string',
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: enumToArray(MasterdataSyncStatus).map((val) => {
        return { id: val, label: val }
      })
    }
  },
  {
    name: 'readyToSync',
    header: 'Ready to sync',
    defaultFlex: 0.25,
    sortable: false,
    type: 'string',
    render: ({ data }: any) => {
      return data.dynamicsHash && data.inRiverHash ? 'Yes' : 'No'
    }
  },
  {
    name: 'lastUpdated',
    header: 'Last Updated',
    defaultFlex: 0.5,
    type: 'string',
    render: ({ data }: any) => {
      return new Date(data.lastUpdated).toLocaleString()
    },
    sort: (date1: string, date2: string) => {
      return new Date(date1).getTime() - new Date(date2).getTime()
    }
  }
]

export default gridColumns
