import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter
} from 'react-pro-sidebar'
import {
  FaClipboardList,
  FaSyncAlt,
  FaRegEnvelopeOpen,
  FaTh,
  FaPersonBooth,
  FaRedo,
  FaTruckMoving,
  FaExclamationTriangle
} from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import authService from '../../services/auth-service'

interface SidebarProps {
  collapsed: boolean
  handleCollapse: (collapse: boolean) => void
}

const Sidebar = ({ collapsed, handleCollapse }: SidebarProps) => {
  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split('/')[1]
  const isLoggedIn = useAppSelector((state) => state.authReducer.isLoggedIn)
  const dispatch = useAppDispatch()

  const handleLogout = () => {
    if (isLoggedIn) {
      authService.logout(dispatch)
    }
  }

  return (
    <ProSidebar breakPoint="md" collapsed={collapsed}>
      <SidebarHeader>
        <small
          className="collapse-sidebar-toggle"
          onClick={() => handleCollapse(!collapsed)}
        >
          {collapsed ? '→' : '←'}
        </small>
        <img
          src="https://www.beerwulf.com/static/dist/assets/img/beerwulf-text-logo.svg"
          alt="Beerwulf"
        />
      </SidebarHeader>
      {isLoggedIn && (
        <SidebarContent>
          <Menu className="main-menu">
            <MenuItem
              icon={<FaTh />}
              className={splitLocation === 'dashboard' ? 'active' : ''}
            >
              <Link to="/dashboard">Dashboard</Link>
            </MenuItem>
            <MenuItem
              icon={<FaSyncAlt />}
              className={splitLocation === 'syncmasterdata' ? 'active' : ''}
            >
              <Link to="/syncmasterdata">Sync master data</Link>
            </MenuItem>
            <MenuItem
              icon={<FaRegEnvelopeOpen />}
              className={splitLocation === 'openordersoverview' ? 'active' : ''}
            >
              <Link to="/openordersoverview">Open orders</Link>
            </MenuItem>
            <MenuItem
              icon={<FaExclamationTriangle />}
              className={splitLocation === 'orderretry' ? 'active' : ''}
            >
              <Link to="/orderretry">Retry failed orders</Link>
            </MenuItem>
            {/* <MenuItem
            icon={<FaTruckLoading />}
            className={splitLocation === 'preordersoverview' ? 'active' : ''}
          >
            <Link to="/preordersoverview">Preorders</Link>
          </MenuItem> */}
            <MenuItem
              icon={<FaRedo />}
              className={splitLocation === 'reordertool' ? 'active' : ''}
            >
              <Link to="/reordertool">Reorder tool</Link>
            </MenuItem>
            <MenuItem
              icon={<FaClipboardList />}
              className={splitLocation === 'bulkordertool' ? 'active' : ''}
            >
              <Link to="/bulkordertool">Bulk order tool</Link>
            </MenuItem>
            <MenuItem
              icon={<FaTruckMoving />}
              className={splitLocation === 'carrierselection' ? 'active' : ''}
            >
              <Link to="/carrierselection">Carrier selection</Link>
            </MenuItem>
          </Menu>
        </SidebarContent>
      )}
      <SidebarFooter>
        <Menu>
          {/* <MenuItem icon={<FaExclamationTriangle />}>Alerts</MenuItem>
          <MenuItem icon={<FaRegSun />}>Settings</MenuItem>
          <MenuItem icon={<FaSmileBeam />}>My account</MenuItem> */}
          <MenuItem
            icon={<FaPersonBooth />}
            onClick={handleLogout}
            className={splitLocation === 'login' ? 'active' : ''}
          >
            <Link to={isLoggedIn ? '/logout' : '/login'}>
              {isLoggedIn ? 'Logout' : 'Login'}
            </Link>
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  )
}

export default Sidebar
