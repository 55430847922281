import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.REACT_APP_USERPORTALAPIURL

const sendPreorders = async (
  warehouseCode: string,
  deliveryDate: Date
): Promise<void> => {
  await axios.post(
    API_URL + 'api/preorder/sendPreorders',
    {
      warehouseCode,
      deliveryDate
    },
    { headers: authHeader() }
  )
}

const preOrderService = {
  sendPreorders
}

export default preOrderService
