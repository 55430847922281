const BulkOrderTool = () => {
  return (
    <div>
      <h1>This is the bulk order tool.</h1>
      <a
        href={process.env.REACT_APP_BULKORDER_URL}
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Bulk order tool
      </a>
    </div>
  )
}

export default BulkOrderTool
