import React, { useEffect, useState } from 'react'
import authService from '../../services/auth-service'
import { useAppDispatch } from '../../app/hooks'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(true)
  const [invalidCredentialsEntered, setInvalidCredentialsEntered] =
    useState(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleLogin = async () => {
    const success = await authService.login(dispatch, username, password)
    if (success) {
      setInvalidCredentialsEntered(false)
      navigate('/dashboard')
    } else {
      setInvalidCredentialsEntered(true)
    }
  }

  const handleFormSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!loginButtonDisabled) {
      await handleLogin()
    }
  }

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setPassword(e.target.value)
  }

  useEffect(() => {
    if (username.trim() === '' || password.trim() === '') {
      setLoginButtonDisabled(true)
    } else {
      setLoginButtonDisabled(false)
    }
  }, [username, password])

  return (
    <div className="login">
      <h1>Login to the Operations Portal</h1>
      <form
        className="login-form"
        onSubmit={async (e) => await handleFormSubmit(e)}
      >
        <input
          type="text"
          placeholder="Email address"
          onChange={handleUsernameChange}
        />
        <input
          type="password"
          placeholder="Password"
          onChange={handlePasswordChange}
        />
        {invalidCredentialsEntered && (
          <small className="error-text">Invalid credentials entered.</small>
        )}
        <div>
          <button
            type="submit"
            className="submit"
            disabled={loginButtonDisabled}
          >
            Login
          </button>
        </div>
      </form>
    </div>
  )
}

export default Login
