import OpenOrdersGrid from './OpenOrdersGrid'

const OpenOrdersOverview = () => {
  return (
    <main className="open-orders-overview">
      <h1>Open Orders Overview</h1>
      <div className="open-orders-grid-container">
        <OpenOrdersGrid />
      </div>
    </main>
  )
}

export default OpenOrdersOverview
