import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'

interface RequireAuthArgs {
  children: JSX.Element
  redirectTo?: string
}

const RequireAuth = (args: RequireAuthArgs): JSX.Element => {
  const location = useLocation()
  const isLoggedIn = useAppSelector((state) => state.authReducer.isLoggedIn)
  const redirect = args.redirectTo ?? '/login'
  return isLoggedIn ? (
    args.children
  ) : (
    <Navigate to={redirect} replace state={location.pathname} />
  )
}

export default RequireAuth
